<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Detalle del estudio</p>
      <button
          type="button"
          class="delete"
          @click="$emit('close')"/>
    </header>
    <section class="modal-card-body">
      <p><strong>Clave: </strong>{{study.code}}
      <strong>Nombre: </strong>{{study.name}}</p>
      <div v-if="study.sub_studies">
        <p><strong>Subestudios: </strong></p>
        <ul>
        <li v-for="substudy in study.sub_studies" style="list-style-type: circle; margin-left: 20px">
          <p><strong>Clave: </strong>{{substudy.code}}
          <strong>Nombre: </strong>{{substudy.name}}</p>
          <div v-if="substudy.sub_studies">
            <p><strong>Subestudios: </strong></p>
            <ul>
              <li v-for="sub_substudy in substudy.sub_studies" style="list-style-type: square; margin-left: 40px">
                <p><strong>Clave: </strong>{{sub_substudy.code}}
                <strong>Nombre: </strong>{{sub_substudy.name}}</p>
                <div v-if="sub_substudy.sub_studies">
                  <p><strong>Subestudios: </strong></p>
                  <ul>
                    <li v-for="sub_sub_substudy in sub_substudy.sub_studies" style="list-style-type: disclosure-closed; margin-left: 60px">
                      <p><strong>Clave: </strong>{{sub_sub_substudy.code}}
                      <strong>Nombre: </strong>{{sub_sub_substudy.name}}</p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li>
        </ul>
      </div>
      <p><strong></strong></p>
    </section>
    <footer class="modal-card-foot">
      <b-button
          label="Cerrar"
          @click="$emit('close')" />
    </footer>
  </div>
</template>

<script>
  export default {
    name: 'ModalStudyDetail',
    props: {
      code: String
    },
    data() {
      return{
        study: {}
      }
    },
    mounted() {
      this.getData();
    },
    methods: {
      getData() {
        this.$loader.show();
        this.$http.get(`/studies/${this.code}?full=true`)
            .then(({data}) => {
              this.study = data.data;
            })
            .finally(() => {
              this.$loader.hide();
            })
      }
    }
  }
</script>

<style scoped>

</style>