<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Seleccion de Ventas por teléfono</p>
    </header>
    <section class="modal-card-body">
      <b-table
          :data="dataSales.data"
          searchable
          :selected.sync="selected"
          @dblclick="onSelect"
          paginated
          per-page="20"
          :backend-filtering="true"
          pagination-simple
          :current-page="dataSales.meta ? parseInt(dataSales.meta.current_page) : parseInt(1)"
          :total="dataSales.meta ? parseInt(dataSales.meta.total): 0"
          backend-pagination
          @filters-change="onFiltersChange"
          @page-change="onPageChange">
        <b-table-column searchable v-slot="props" field="code" label="Folio">{{ props.row.code }}</b-table-column>
        <b-table-column searchable v-slot="props" field="name" label="Nombre">{{
            props.row.patient.name
          }}
        </b-table-column>
        <b-table-column searchable v-slot="props" field="last_name" label="Apellido Paterno">
          {{ props.row.patient.last_name }}
        </b-table-column>
        <b-table-column searchable v-slot="props" field="other_name" label="Apellido Materno">
          {{ props.row.patient.other_name }}
        </b-table-column>
        <b-table-column v-slot="props" field="phone" label="Teléfono">{{ props.row.patient.phone }}</b-table-column>
        <b-table-column v-slot="props" field="email" label="Email">{{ props.row.patient.email }}</b-table-column>
        <b-table-column v-slot="props" field="status" label="Estado">{{ props.row.status }}</b-table-column>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon
                    icon="frown"
                    size="is-large">
                </b-icon>
              </p>
              <p>Lo sentimos no hay ninguna venta por teléfono que coincida con tu búsqueda.</p>
            </div>
          </section>
        </template>
      </b-table>
    </section>
    <footer class="modal-card-foot">
      <button class="button" @click="$parent.close()">Cerrar</button>
      <button class="button is-primary" type="button">Seleccionar</button>
    </footer>
  </div>

</template>

<script>
export default {
  name: "ModalSelectSale",
  data() {
    return {
      selected: {},
    }
  },
  props: {
    dataSales: Object,
  },
  methods: {
    onFiltersChange(params) {
      console.log('search', params)
      this.$emit('search', params);
    },
    onSelect(data) {
      this.$parent.close();
      this.$emit('select', data)
    },
    onPageChange(page){
      this.$emit('page-change',page);
    },
  }
}
</script>

<style scoped>

</style>