<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Registrar pago</p>
      <button
          type="button"
          class="delete"
          @click="$parent.close()"/>
    </header>
    <section class="modal-card-body">
      <b-field label="Medio de pago">
        <b-select v-model="selectedPayment"
                  :disabled="['35'].includes(this.$auth.user().active_branch_office.toString())" expanded>
          <option value="branchOffice">Pago Sucursal</option>
          <option value="callCenter">Pago Call Center</option>
        </b-select>
      </b-field>
      <br>
      <b-field label="Forma de pago">
        <b-select expanded required v-model="selectedPaymentType">
          <option v-for="method in filteredPaymentsTypes" :key="method.code" :value="method.code">{{
              method.description
            }}
          </option>

        </b-select>
      </b-field>

      <form ref="form" @submit.prevent="handleFileUpload" id="form-file">
        <b-upload v-model="file" expanded
                  accept=".pdf,.png,.jpg,.jpeg,.gif,.tiff,.webp,.bmp"
                  class="file-label mt-4"
                  v-if="selectedPayment === 'callCenter'" name="file">
            <span class="button is-primary is-fullwidth">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">Click para subir comprobante de pago</span>
            </span>
        </b-upload>
      </form>
      <div class="mt-4">
        <span v-if="file">
                {{ "Se ha seleccionado el archivo: " + file.name }}
        </span>
      </div>


    </section>
    <footer class="modal-card-foot">
      <button class="button" @click="$parent.close()">Cerrar</button>
      <button class="button is-primary" form="form-file" type="submit">Guardar</button>
    </footer>
  </div>
</template>

<script>
import branchoffice from "@/store/modules/branchoffice";
import {mapState, mapGetters} from "vuex";

export default {
  name: "ModalRegisterPayment",
  computed: {
    ...mapState({
      methods: state => state.main.paymentTypes
    }),
    branchoffice() {
      return branchoffice
    },
    filteredPaymentsTypes() {
      let result = [];
      if (this.selectedPayment === 'branchOffice') {
        result = this.methods.filter(method => ['EFECT', 'EFEDL', 'TARJD', 'TARJT', 'TRANS'].includes(method.code));
      } else {
        result = this.methods.filter(method => ['TARJD', 'TARJT', 'TRANS'].includes(method.code));
      }
      return result;
    }
  },
  props: {
    code: String
  },
  data() {
    return {
      file: null,
      fileName: '',
      selectedPayment: '',
      selectedPaymentType: ''
    }
  },
  mounted() {
    if (['35'].includes(this.$auth.user().active_branch_office.toString())) {
      this.selectedPayment = 'callCenter';
    }
  },
  methods: {
    handleFileUpload(event) {
    const formData = new FormData(event.target);
    formData.append('type', this.selectedPaymentType)
    this.$loader.show();
    this.$http.post(`/phone-sell/${this.code}/payment`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(() => {
          this.$parent.close();
          this.$buefy.dialog.alert({
            title: 'Procesado con éxito',
            type: 'is-success',
            hasIcon:true,
            icon:'',
            message: 'Documento almacenado con éxito'
          });
          this.$emit('success');
    }).finally(() => {
      this.$loader.hide();
    });
    }
  },
}
</script>

<style scoped>

</style>