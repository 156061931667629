import axios from 'axios';

const localStorageItem = 'branch_offices';
const branchOffices = JSON.parse(localStorage.getItem(localStorageItem) || '[]');

export default {
    state: {
        branchOffices
    },
    actions: {
        async fetchBranchOffices({commit}) {
            const response = await axios.get('main/branch-offices')
                .then(({data}) => {
                   return data || {}
                });
            commit('setBranchOffices', response.data)
        }
    },
    mutations: {
        setBranchOffices(state, data) {
            state.branchOffices = data;
            localStorage.setItem(localStorageItem, JSON.stringify(state.branchOffices));
        },
        addBranchOffice(state, data) {
            state.branchOffices.push(data)
            localStorage.setItem(localStorageItem, JSON.stringify(state.branchOffices));
        },
    },
}